/// Get a z-index value from a layer name
/// @access public
/// @param {String} $layer - Layer’s name
/// @return {Number}
/// @require $z-indexes
@function z($layer) {
  @return map-get($z-indexes, $layer);
}

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. ' + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

/// Helper to size an element
/// @param {Length} $width
/// @param {Length} $height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/* Responsive Break Points */
$breakpoints: (
  'min-min': (
    min-width: 576px,
  ),
  'min-medium': (
    min-width: 768px,
  ),
  'min-large': (
    min-width: 992px,
  ),
  'min-huge': (
    min-width: 1200px,
  ),
  'max-min': (
    max-width: 768px,
  ),
  'max-medium': (
    max-width: 768px,
  ),
  'max-large': (
    max-width: 992px,
  ),
  'max-huge': (
    max-width: 1200px,
  ),
);

/* Colors */
$background-color: #f4f5fa;
$header-color: white;
$sub-black-color: #333333;
$black-color: #000000;
$description-black-color: #383838;
$blue-color: #216edc;
$primary-color: #0057e2;
$danger-color: #d80027;

/* Text Color Gradient */
$text-color-gradient-primary: -webkit-linear-gradient(
  0deg,
  #08244f 0%,
  #0d3d85 37.42%,
  #2557a6 81.7%,
  #113b80 100%
);

/* Size */
$default-min-height: 750px;

/// Z-indexes map, gathering all Z layers of the application
/// @access private
/// @type Map
/// @prop {String} key - Layer’s name
/// @prop {Number} value - Z value mapped to the key
$z-indexes: (
  'modal': 30,
  'header': 25,
  'dropdown': 20,
  'normal': 10,
  'default': 1,
  'below': -1,
);

.pr-faq {
  padding-top: 70px;
  padding-bottom: 70px;
  background: #ffffff;

  .custom-container {
    width: 960px;
    max-width: 100%;
  }

  .content {
    margin-bottom: 80px;
  }

  .title {
    width: 770px;
    max-width: 100%;
    margin: 0 auto;
    font-size: 46px;
    font-weight: 700;
    line-height: 55.67px;
    text-align: center;
    color: $black-color;
  }

  .description {
    word-break: break-word;
    white-space: break-spaces;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
    color: $description-black-color;
    margin-top: 20px;
  }

  .list-content-items {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .wrapper-faq-content {
    .faq-content {
      padding-bottom: 32px;
      border-bottom: 1px solid #cfe1ff;
    }

    .faq-top {
      position: relative;
      display: flex;
      display: flex;
      align-items: center;
    }

    .btn-icon-arrow-down {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 0.5s;
    }

    .faq-index {
      width: 29px;
      height: 29px;
      font-size: 24px;
      font-weight: 700;
      line-height: 29.05px;
      text-align: left;
      margin-right: 9px;
      color: $black-color;
    }

    .faq-title {
      font-size: 18px;
      font-weight: 700;
      line-height: 21.78px;
      text-align: left;
      color: $black-color;
      padding-right: 25px;
    }

    .faq-description {
      word-break: break-word;
      white-space: break-spaces;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      text-align: left;
      color: $description-black-color;
      opacity: 1;
      padding-left: 38px;
      margin-top: 12px;
      transition: all 1s;
    }

    .faq-content.hide {
      .faq-description {
        height: 0;
        opacity: 0;
        overflow: hidden;
      }

      .btn-icon-arrow-down {
        transform: translateY(-50%) rotate(-90deg);
      }
    }
  }

  .image-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;

    > img {
      width: 700px;
      max-width: 100%;
      max-height: 500px;
      object-fit: contain;
    }
  }

  @include respond-to('max-min') {
    padding-top: 32px;
    padding-bottom: 30px;

    .custom-container {
    }

    .content {
      margin-bottom: 24px;
    }

    .title {
      font-size: 26px;
      font-weight: 700;
      line-height: 31.47px;
      text-align: center;
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
      margin-top: 24px;
    }

    .title {
      font-size: 26px;
      font-weight: 700;
      line-height: 31.47px;
      text-align: center;
    }

    .list-content-items {
      gap: 24px;
    }

    .wrapper-faq-content {
      .faq-content {
        padding-bottom: 16px;
      }

      .faq-index {
        font-size: 18px;
        font-weight: 700;
        line-height: 21.78px;
      }

      .faq-title {
        font-size: 14px;
        font-weight: 700;
        line-height: 16.94px;
      }

      .faq-description {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
